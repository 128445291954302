<script>
import SVars from "@/components/Settings/SVars";
import SettingService from "@/services/SettingService";

export default {
    name: "VTemplates",
    components: {
        SVars
    },
    props: {},
    data() {
        return {
            loading: false,
            vars: null,
            tpls: [],
            showing: "",
            groups: [
                {
                    label: this.$t("invoice_templates.contract"),
                    value: "contract"
                },
                {
                    label: this.$t("invoice_templates.contract_regulation"),
                    value: "contract_regulation"
                },
                {
                    label: this.$t("invoice_templates.subscription"),
                    value: "subscription"
                },
                {
                    label: this.$t("invoice_templates.charges"),
                    value: "charge"
                }
            ]
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.INVOICE_MGMT);
        this.fetchData();
    },
    methods: {
        /**
         * Fetch settings data + vars
         */
        async fetchData() {
            try {
                const r1 = await SettingService.getNamespace(
                    "invoice_templates"
                );
                this.tpls = r1.data.data;

                const r2 = await SettingService.getNamespaceVariables(
                    "invoice_templates"
                );
                this.vars = r2.data.data;
            } catch (e) {}
            this.loading = false;
        },
        /**
         *
         */
        async saveData() {
            try {
                this.loading = true;
                const r = await SettingService.saveMultiple({
                    invoice_templates: this.tpls
                });
                this.$notify({
                    message: this.$t("invoice_templates.data_saved"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });
            } catch (e) {}
            this.loading = false;
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag> </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else>
                            <div class="row">
                                <div class="col">
                                    <FormulateInput
                                        type="select"
                                        v-model="showing"
                                        :options="groups"
                                        :label="
                                            $t('invoice_templates.select_group')
                                        "
                                        :placeholder="
                                            $t(
                                                'invoice_templates.select_template_group'
                                            )
                                        "
                                    />
                                </div>
                            </div>
                        </template>

                        <!-- CONTRACT -->
                        <template v-if="showing == 'contract'">
                            <h1>{{ $t("invoice_templates.contract") }}</h1>

                            <div class="row mb-4">
                                <div class="col-12">
                                    <div class="text-right">
                                        <base-button
                                            v-if="ACL_RW"
                                            type="primary"
                                            @click="saveData"
                                            >{{
                                                $t(
                                                    "invoice_templates.save_changes"
                                                )
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- contract heading -->
                            <h2>
                                {{ $t("invoice_templates.contract_heading") }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.contract_heading.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.contract_heading.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.contract_heading" />
                                </div>
                            </div>

                            <hr />

                            <!-- Text 1-->
                            <h2>
                                {{ $t("invoice_templates.contract_text1") }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.contract_text1.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.contract_text1.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.contract_text1" />
                                </div>
                            </div>

                            <hr />
                            <!-- text 2-->
                            <h2>
                                {{ $t("invoice_templates.contract_text2") }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.contract_text2.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.contract_text2.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.contract_text2" />
                                </div>
                            </div>

                            <hr />
                            <h2>
                                {{
                                    $t(
                                        "invoice_templates.contract_product_wind"
                                    )
                                }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.contract_product_wind.da"
                                        type="textarea"
                                        :help="
                                            $t('invoice_templates.product_help')
                                        "
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.contract_product_wind.en"
                                        type="textarea"
                                        :help="
                                            $t('invoice_templates.product_help')
                                        "
                                    />
                                </div>
                                <div class="col">
                                    <s-vars
                                        :vars="vars.contract_product_wind"
                                    />
                                </div>
                            </div>

                            <hr />
                            <h2>
                                {{
                                    $t(
                                        "invoice_templates.contract_product_solar"
                                    )
                                }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.contract_product_solar.da"
                                        type="textarea"
                                        :help="
                                            $t('invoice_templates.product_help')
                                        "
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.contract_product_solar.en"
                                        type="textarea"
                                        :help="
                                            $t('invoice_templates.product_help')
                                        "
                                    />
                                </div>
                                <div class="col">
                                    <s-vars
                                        :vars="vars.contract_product_solar"
                                    />
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="text-right">
                                        <base-button
                                            v-if="ACL_RW"
                                            type="primary"
                                            @click="saveData"
                                            >{{
                                                $t(
                                                    "invoice_templates.save_changes"
                                                )
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- END OF CONTRACT -->

                        <!-- CONTRACT REGULATION -->
                        <template v-if="showing == 'contract_regulation'">
                            <h1>
                                {{
                                    $t("invoice_templates.contract_regulation")
                                }}
                            </h1>

                            <div class="row mb-4">
                                <div class="col-12">
                                    <div class="text-right">
                                        <base-button
                                            v-if="ACL_RW"
                                            type="primary"
                                            @click="saveData"
                                            >{{
                                                $t(
                                                    "invoice_templates.save_changes"
                                                )
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_heading.da
                                        "
                                        type="textarea"
                                        :label="
                                            $t(
                                                'invoice_templates.contract_regulation_heading'
                                            )
                                        "
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_heading.en
                                        "
                                        type="textarea"
                                        :label="
                                            $t(
                                                'invoice_templates.contract_regulation_heading'
                                            )
                                        "
                                    />
                                </div>
                                <div class="col">
                                    <s-vars
                                        :vars="vars.contract_regulation_heading"
                                    />
                                </div>
                            </div>

                            <hr />

                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_text1.da
                                        "
                                        type="textarea"
                                        :label="
                                            $t(
                                                'invoice_templates.contract_regulation_text1'
                                            )
                                        "
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_text1.en
                                        "
                                        type="textarea"
                                        :label="
                                            $t(
                                                'invoice_templates.contract_regulation_text1'
                                            )
                                        "
                                    />
                                </div>
                                <div class="col">
                                    <s-vars
                                        :vars="vars.contract_regulation_text1"
                                    />
                                </div>
                            </div>

                            <hr />
                            
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_text2.da
                                        "
                                        type="textarea"
                                        :label="
                                            $t(
                                                'invoice_templates.contract_regulation_text2'
                                            )
                                        "
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_text2.en
                                        "
                                        type="textarea"
                                        :label="
                                            $t(
                                                'invoice_templates.contract_regulation_text2'
                                            )
                                        "
                                    />
                                </div>
                                <div class="col">
                                    <s-vars
                                        :vars="vars.contract_regulation_text2"
                                    />
                                </div>
                            </div>
                            <hr />
                            <!-- Regulation wind-->
                            <h2>
                                {{
                                    $t(
                                        "invoice_templates.contract_regulation_wind"
                                    )
                                }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_wind.da
                                        "
                                        type="textarea"
                                        :help="
                                            $t('invoice_templates.regulation_product_help')
                                        "
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_wind.en
                                        "
                                        type="textarea"
                                        :help="
                                            $t('invoice_templates.regulation_product_help')
                                        "
                                    />
                                </div>
                                <div class="col">
                                    <s-vars
                                        :vars="vars.contract_regulation_wind"
                                    />
                                </div>
                            </div>
                            
                            <hr />
                            <h2>
                                {{
                                    $t(
                                        "invoice_templates.contract_regulation_solar"
                                    )
                                }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_solar.da
                                        "
                                        type="textarea"
                                        :help="
                                            $t('invoice_templates.regulation_product_help')
                                        "
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="
                                            tpls.contract_regulation_solar.en
                                        "
                                        type="textarea"
                                        :help="
                                            $t('invoice_templates.regulation_product_help')
                                        "
                                    />
                                </div>
                                <div class="col">
                                    <s-vars
                                        :vars="vars.contract_regulation_solar"
                                    />
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="text-right">
                                        <base-button
                                            v-if="ACL_RW"
                                            type="primary"
                                            @click="saveData"
                                            >{{
                                                $t(
                                                    "invoice_templates.save_changes"
                                                )
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- END OF CONTRACT REGULATION -->

                        <!-- SUBSCRIPTION -->
                        <template v-if="showing == 'subscription'">
                            <h1>{{ $t("invoice_templates.subscription") }}</h1>

                            <div class="row mb-4">
                                <div class="col-12">
                                    <div class="text-right">
                                        <base-button
                                            v-if="ACL_RW"
                                            type="primary"
                                            @click="saveData"
                                            >{{
                                                $t(
                                                    "invoice_templates.save_changes"
                                                )
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- contract heading -->
                            <h2>
                                {{
                                    $t("invoice_templates.subscription_heading")
                                }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.subscription_heading.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.subscription_heading.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.subscription_heading" />
                                </div>
                            </div>

                            <hr />

                            <!-- Text 1-->
                            <h2>
                                {{ $t("invoice_templates.subscription_text1") }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.subscription_text1.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.subscription_text1.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.subscription_text1" />
                                </div>
                            </div>

                            <hr />
                            <!-- text 2-->
                            <h2>
                                {{ $t("invoice_templates.subscription_text2") }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.subscription_text2.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.subscription_text2.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.subscription_text2" />
                                </div>
                            </div>

                            <hr />

                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="text-right">
                                        <base-button
                                            v-if="ACL_RW"
                                            type="primary"
                                            @click="saveData"
                                            >{{
                                                $t(
                                                    "invoice_templates.save_changes"
                                                )
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- END OF SUBSCRIPTION -->

                        <!-- CHARGE -->
                        <template v-if="showing == 'charge'">
                            <h1>{{ $t("invoice_templates.charge") }}</h1>

                            <div class="row mb-4">
                                <div class="col-12">
                                    <div class="text-right">
                                        <base-button
                                            v-if="ACL_RW"
                                            type="primary"
                                            @click="saveData"
                                            >{{
                                                $t(
                                                    "invoice_templates.save_changes"
                                                )
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                            </div>
                            <!-- contract heading -->
                            <h2>
                                {{ $t("invoice_templates.charge_heading") }}
                            </h2>

                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.charge_heading.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.charge_heading.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.charge_heading" />
                                </div>
                            </div>

                            <hr />

                            <!-- Text 1-->
                            <h2>
                                {{ $t("invoice_templates.charge_text1") }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.charge_text1.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.charge_text1.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.charge_text1" />
                                </div>
                            </div>

                            <hr />
                            <!-- text 2-->
                            <h2>
                                {{ $t("invoice_templates.charge_text2") }}
                            </h2>
                            <div class="row">
                                <div class="col">
                                    <flag iso="dk" />
                                    <FormulateInput
                                        v-model="tpls.charge_text2.da"
                                        type="textarea"
                                    />

                                    <flag iso="gb" />
                                    <FormulateInput
                                        v-model="tpls.charge_text2.en"
                                        type="textarea"
                                    />
                                </div>
                                <div class="col">
                                    <s-vars :vars="vars.charge_text2" />
                                </div>
                            </div>

                            <hr />

                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="text-right">
                                        <base-button
                                            v-if="ACL_RW"
                                            type="primary"
                                            @click="saveData"
                                            >{{
                                                $t(
                                                    "invoice_templates.save_changes"
                                                )
                                            }}</base-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- END OF CHARGE -->
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>
