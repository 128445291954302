<script>
import dayjs from "dayjs";
import SingleDetailsModal from "@/components/Momentum/NewContracts/SingleDetailsModal";
import ContractDetailService from "@/services/ContractDetailService";
import InvoiceService from "@/services/InvoiceService";
import VInvoiceDetail from "@/components/Momentum/Contracts/VInvoiceDetail";
import VContractWidget from "@/components/Momentum/Contracts/VContractWidget";
import VFilters from "@/components/Search/VFilters";
import MGConsts from "@/mixins/MGConsts";
import { t } from "element-ui/lib/locale";

export default {
    name: "VReady",
    mixins: [MGConsts],
    components: {
        VInvoiceDetail,
        VContractWidget,
        VFilters,
        SingleDetailsModal
    },

    data() {
        return {
            dateRanges: [],
            job_id: null, // job_id bg job
            job_loading: false, // bg job loading
            unfoldAll: false,
            loading: false,
            invoices: [], // main invoices
            selectedInvoices: [], // checkbox containing selections
            invoiceDates: {},
            showResult: false, // final result after processing
            detailsModal: false,
            contractContext: {},
            counts: {
                ready: 0,
                missing: 0,
                error: 0
            }
        };
    },
    computed: {
        today() {
            return dayjs().format("YYYY-MM-DD");
        },
        selectAll: {
            get: function() {
                return this.invoices
                    ? this.selectedInvoices.length == this.invoices.length
                    : false;
            },

            set: function(v) {
                let selected = [];

                if (v) {
                    this.invoices.forEach(function(v, index) {
                        selected.push(index);
                    });
                }

                this.selectedInvoices = selected;
            }
        }
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.INVOICE_MGMT);
        await this.fetchFilters();
        this.apiPaginator.sort = "invoicing_date";
        this.apiPaginator.direction = "asc";
        this.apiFilterView = this.dateRanges[1].value;
        this.$apiEnableWatcher();
        await this.fetchData(true);
        this.fixCustomDates();
        this.loading = false;
    },
    methods: {
        fixCustomDates() {
            this.invoices.forEach(e => {
                if (e.balance_src.regulation !== 0.0) {
                    this.$set(this.invoiceDates, e.id, this.today);
                }
            });
        },
        showDetailsModal(cd) {
            console.log("Fire details modal", cd.id);
            this.contractContext = cd;
            this.detailsModal = true;
        },
        /**
         * Fetch (once) active filters
         * It does also localize the label sent from the backend
         */
        async fetchFilters() {
            try {
                this.loading = true;
                const r = await InvoiceService.invoiceFilters();
                let range = [];

                r.data.data.forEach(v => {
                    range.push({
                        label: this.$t("invoices.dropdown_filter_until", {
                            date: this.$d(new Date(v.label), "short")
                        }),
                        value: v.value
                    });
                });

                this.dateRanges = range;
            } catch (e) {}
        },
        /**
         * Fetch invoices
         */
        async fetchData(loading = false) {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["cview"] = "ready";
                const {
                    data
                } = await ContractDetailService.GET_contractInvoicing(params);
                // These two must be reset
                this.invoices = [];
                this.selectedInvoices = [];

                data.data.forEach(obj => {
                    this.invoices.push({ ...obj, visible: false });
                });

                this.counts.ready = data.meta.ready_count;
                this.counts.missing = data.meta.missing_count;
                this.counts.error = data.meta.error_count;
                this.$emit("updateCounts", this.counts);
            } catch (e) {
                console.log("fe e", e);
            }

            this.loading = loading;
        },
        /**
         * Send invoices
         */
        async sendInvoices() {
            try {
                this.job_loading = true;
                let copy = [];

                this.selectedInvoices.forEach(key => {
                    copy.push(this.invoices[key]);
                });

                const r = await InvoiceService.invoiceContracts({
                    invoices: copy,
                    invoiceDates: this.invoiceDates
                });
                this.job_id = r.data.data.job_id;
            } catch (e) {
                this.job_loading = false;
                this.$swal({
                    icon: "error",
                    title: "Faktura-fejl",
                    text:
                        "Copy paste hele teksten og kontakt supporten: " +
                        JSON.stringify(e)
                });
                console.log("Exception", e);
            }
        },
        /**
         * Ready
         */
        async doneInvoices(result) {
            this.job_loading = false;
            this.job_id = null;
            console.log(result);
            this.result = result;
            this.showResult = true;
            //this.fetchData();
        },
        async closeInvoices() {
            this.showResult = false;
            await this.fetchData();
        },
        downloadCSV() {
            const baseURL =
                window.location.protocol +
                window.location.host +
                "/v1/contract-details/contract-invoicing";

            // Define the query object with GET variables
            let params = this.$buildParams();
            params["cview"] = "ready";
            params["csv"] = "1";

            // Create a URL object and append the URL-encoded query string
            const url = new URL(baseURL);
            url.search = new URLSearchParams(params).toString();

            // Create a hidden link element
            const link = document.createElement("a");
            link.style.display = "none";
            document.body.appendChild(link);

            // Set the link's href and download attributes
            link.setAttribute("href", url.toString());
            link.setAttribute("download", "invoices-ready.csv");

            // Trigger a click event on the link to start the download
            link.click();

            // Remove the link element from the DOM
            document.body.removeChild(link);
        }
    }
};
</script>

<template>
    <div class="v-new-ready">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        :filter-views="dateRanges"
                        :filter-view="apiFilterView"
                        @filter-view-change="$handleFilterView($event)"
                        :filter-name="$t('invoices.period')"
                        v-model="apiSearchFilter.customer"
                        :search="true"
                        :search-placeholder="$t('invoices.search')"
                    >
                        <template slot="left">
                            <base-button
                                type="secondary"
                                @click="sendInvoices"
                                :disabled="invoices.length === 0"
                                v-if="ACL_RW"
                                ><i class="far fa-file-export"></i>
                                {{ $t("invoices.send_selected") }}</base-button
                            >
                        </template>
                        <template v-slot:right>
                            <base-button
                                size="sm"
                                type="secondary"
                                @click="downloadCSV"
                                ><i class="fa fa-file-csv"></i>
                                {{ $t("invoices.export_to_csv") }}</base-button
                            >
                        </template>
                    </v-filters>
                </div>
            </div>

            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />

                    <card body-classes="px-0" v-else>
                        <template v-if="invoices.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><input
                                                    type="checkbox"
                                                    v-model="selectAll"
                                            /></v-th>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="invoicing_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_date_short"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="contract_id"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.invoice_id"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="entry_type"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.type")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th></v-th>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Customers.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.customer")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="FinanceUsers.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.finance_responsible"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="OperationsUsers.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "invoices.operations_responsible"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="base_fee"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("invoices.amount")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-center"> </v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="(cd, index) in invoices"
                                            :key="index"
                                        >
                                            <v-td
                                                ><input
                                                    type="checkbox"
                                                    :value="index"
                                                    v-model="selectedInvoices"
                                            /></v-td>
                                            <v-td>
                                                <template
                                                    v-if="
                                                        cd.balance_src
                                                            .regulation !== 0.0
                                                    "
                                                >
                                                    <div
                                                        class="d-flex"
                                                        style="min-width: 120px"
                                                    >
                                                        <div class="mr-2">
                                                            <input
                                                                type="radio"
                                                                v-model="
                                                                    invoiceDates[
                                                                        cd.id
                                                                    ]
                                                                "
                                                                :value="
                                                                    cd.invoicing_date
                                                                "
                                                            />
                                                        </div>
                                                        <div>
                                                            {{
                                                                $d(
                                                                    new Date(
                                                                        cd.invoicing_date
                                                                    ),
                                                                    "short"
                                                                )
                                                            }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="d-flex text-warning text-bold"
                                                        style="min-width: 120px;"
                                                    >
                                                        <div class="mr-2">
                                                            <input
                                                                type="radio"
                                                                v-model="
                                                                    invoiceDates[
                                                                        cd.id
                                                                    ]
                                                                "
                                                                :value="today"
                                                            />
                                                        </div>
                                                        <div>
                                                            {{
                                                                $d(
                                                                    new Date(),
                                                                    "short"
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    {{
                                                        $d(
                                                            new Date(
                                                                cd.invoicing_date
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </template>
                                            </v-td>
                                            <v-td class="text-center"
                                                >#{{ cd.contract_id }}</v-td
                                            >
                                            <v-td>
                                                {{ cd.entry_type_name }}
                                            </v-td>
                                            <v-td>
                                                <i
                                                    v-if="
                                                        cd.contract.asset_type
                                                    "
                                                    :class="
                                                        `far ${cd.contract.asset_type.icon} fa-fw`
                                                    "
                                                ></i>
                                                <i
                                                    v-if="
                                                        cd.contract
                                                            .contract_type == 2
                                                    "
                                                    :class="
                                                        `far fa-percent fa-fw`
                                                    "
                                                ></i>
                                            </v-td>
                                            <v-td
                                                ><router-link
                                                    :to="{
                                                        name:
                                                            'CustomersTabManageContract',
                                                        params: {
                                                            customer_id:
                                                                cd.contract
                                                                    .customer
                                                                    .id,
                                                            contract_id:
                                                                cd.contract.id
                                                        }
                                                    }"
                                                >
                                                    {{
                                                        cd.contract.customer
                                                            .name
                                                    }}</router-link
                                                >
                                                <div
                                                    class="small"
                                                    v-if="
                                                        cd.contract
                                                            .invoice_prefix_text
                                                    "
                                                >
                                                    {{ $t("invoice.prefix") }}:
                                                    {{
                                                        cd.contract
                                                            .invoice_prefix_text
                                                    }}
                                                </div>
                                            </v-td>
                                            <v-td>
                                                <template
                                                    v-if="
                                                        cd.contract.finance_user
                                                    "
                                                >
                                                    {{
                                                        cd.contract.finance_user
                                                            .name
                                                    }}
                                                </template>
                                                <template v-else>---</template>
                                            </v-td>
                                            <v-td
                                                ><template
                                                    v-if="
                                                        cd.contract
                                                            .operations_user
                                                    "
                                                >
                                                    {{
                                                        cd.contract
                                                            .operations_user
                                                            .name
                                                    }}
                                                </template>
                                                <template v-else
                                                    >---</template
                                                ></v-td
                                            >
                                            <v-td class="text-right">
                                                <template
                                                    v-if="
                                                        cd.balance_src
                                                            .regulation !== 0.0
                                                    "
                                                >
                                                    <div class="fee-base">
                                                        {{
                                                            $n(cd.base_fee, {
                                                                style:
                                                                    "currency",
                                                                currency:
                                                                    cd.currency,
                                                                currencyDisplay:
                                                                    "code"
                                                            })
                                                        }}
                                                    </div>
                                                    <div class="fee-regulation">
                                                        <template
                                                            v-if="
                                                                cd.balance_src
                                                                    .regulation >
                                                                    0
                                                            "
                                                            >+</template
                                                        >{{
                                                            $n(
                                                                cd.balance_src
                                                                    .regulation,
                                                                {
                                                                    style:
                                                                        "currency",
                                                                    currency:
                                                                        cd.currency,
                                                                    currencyDisplay:
                                                                        "code"
                                                                }
                                                            )
                                                        }}
                                                    </div>
                                                    <div class="fee-balanced">
                                                        {{
                                                            $n(
                                                                cd.balanced_fee,
                                                                {
                                                                    style:
                                                                        "currency",
                                                                    currency:
                                                                        cd.currency,
                                                                    currencyDisplay:
                                                                        "code"
                                                                }
                                                            )
                                                        }}
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    {{
                                                        $n(cd.base_fee, {
                                                            style: "currency",
                                                            currency:
                                                                cd.currency,
                                                            currencyDisplay:
                                                                "code"
                                                        })
                                                    }}
                                                </template>
                                            </v-td>

                                            <v-td
                                                ><i
                                                    class="m-click fa fa-eye fa-lg"
                                                    @click="
                                                        showDetailsModal(cd)
                                                    "
                                                ></i
                                            ></v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </template>
                        <div class="px-mg" v-else>
                            <div class="nice-info">
                                {{ $t("invoice.no_invoices_to_send") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <v-queue
                :name="$t('invoices.creating_invoices')"
                :job_id="job_id"
                :loading="job_loading"
                @task-ready="doneInvoices"
            />
            <single-details-modal
                v-if="detailsModal"
                :contract-detail="contractContext"
                @close="detailsModal = false"
            />
            <portal to="modals" v-if="showResult">
                <modal
                    size="xl"
                    :show="true"
                    :showClose="true"
                    class="invoice-result-modal"
                    @close="closeInvoices"
                >
                    <template slot="header">
                        {{ $t("invoices.result") }}
                    </template>

                    <template slot="default">
                        <v-table class="table table-sm table-mg">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{ $t("invoices.customer") }}</v-th>
                                    <v-th>{{
                                        $t("invoices.invoice_type")
                                    }}</v-th>
                                    <v-th>{{
                                        $t("invoices.invoice_date")
                                    }}</v-th>
                                    <v-th class="text-right">{{
                                        $t("invoices.amount")
                                    }}</v-th>
                                    <v-th></v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr
                                    v-for="(r, index) in result.result"
                                    :key="index"
                                    :class="{
                                        'bg-danger': r.ec_result.status == false
                                    }"
                                >
                                    <template
                                        v-if="r.ec_result.status == false"
                                    >
                                        <v-td
                                            :class="{
                                                'text-white text-bold':
                                                    r.ec_result.status == false
                                            }"
                                        >
                                            {{ r.invoice.debtor.name }}
                                            <div
                                                class="alert alert-danger alert-outline mt-3"
                                                v-if="
                                                    r.ec_result.status == false
                                                "
                                            >
                                                {{
                                                    $t(
                                                        "invoice.error_introduction"
                                                    )
                                                }}
                                                <div
                                                    style="font-style: italic"
                                                    class="mt-2"
                                                >
                                                <pre style="border: 2px dashed white; width: 500px; font-size: 11px; overflow: auto;">{{ r.ec_result.error }}</pre>
                                                </div>
                                            </div>
                                        </v-td>
                                        <v-td class="text-white text-bold">
                                            {{
                                                $matchInHash(
                                                    mgInvoiceTypesHash,
                                                    r.invoice.type
                                                )
                                            }}
                                        </v-td>
                                        <v-td class="text-white text-bold">
                                            <template
                                                v-if="
                                                    r.ec_result.status == true
                                                "
                                            >
                                                {{
                                                    $d(
                                                        new Date(
                                                            r.entity.invoice_date
                                                        ),
                                                        "short"
                                                    )
                                                }}
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td
                                            class="text-right text-white text-bold"
                                        >
                                            ---
                                        </v-td>
                                        <v-td class="text-right">
                                            <i
                                                class="far"
                                                :class="{
                                                    'fa-check':
                                                        r.ec_result.status,
                                                    'fa-times': !r.ec_result
                                                        .status
                                                }"
                                            ></i>
                                        </v-td>
                                    </template>

                                    <template v-else>
                                        <v-td
                                            :class="{
                                                'text-white text-bold':
                                                    r.ec_result.status == false
                                            }"
                                        >
                                            {{ r.invoice.debtor.name }}
                                            <div
                                                class="alert alert-danger alert-outline mt-3"
                                                v-if="
                                                    r.ec_result.status == false
                                                "
                                            >
                                                {{
                                                    $t(
                                                        "invoice.error_introduction"
                                                    )
                                                }}
                                                <div
                                                    style="font-style: italic"
                                                    class="mt-2"
                                                >
                                                    {{ r.ec_result.error }}
                                                </div>
                                            </div>
                                        </v-td>
                                        <v-td
                                            :class="{
                                                'text-white text-bold':
                                                    r.ec_result.status == false
                                            }"
                                        >
                                            {{
                                                $matchInHash(
                                                    mgInvoiceTypesHash,
                                                    r.invoice.type
                                                )
                                            }}
                                        </v-td>
                                        <v-td
                                            :class="{
                                                'text-white text-bold':
                                                    r.ec_result.status == false
                                            }"
                                        >
                                            <template
                                                v-if="
                                                    r.ec_result.status == true
                                                "
                                            >
                                                {{
                                                    $d(
                                                        new Date(
                                                            r.entity.invoice_date
                                                        ),
                                                        "short"
                                                    )
                                                }}
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td
                                            class="text-right"
                                            :class="{
                                                'text-white text-bold':
                                                    r.ec_result.status == false
                                            }"
                                        >
                                            {{
                                                $n(r.entity.total_net_amount, {
                                                    style: "currency",
                                                    currency:
                                                        r.invoice.payload
                                                            .currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}
                                        </v-td>
                                        <v-td class="text-right">
                                            <i
                                                class="far"
                                                :class="{
                                                    'fa-check':
                                                        r.ec_result.status,
                                                    'fa-times': !r.ec_result
                                                        .status
                                                }"
                                            ></i>
                                        </v-td>
                                    </template>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                    </template>
                </modal>
            </portal>
        </div>
    </div>
</template>

<style lang="scss">
.v-new-ready {
    .fee-regulation {
        border-bottom: 2px solid black;
    }
    .fee-balanced {
    }
}
</style>
